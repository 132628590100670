/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { navigate } from "gatsby";

function encode(data) {
	return Object.keys(data)
		.map(
			(key) =>
				encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
		)
		.join("&");
}

const Form = () => {
	const [state, setState] = React.useState({});

	const handleChange = (e) => {
		setState({ ...state, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;
		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({
				"form-name": form.getAttribute("name"),
				...state,
			}),
		})
			.then(() => navigate(form.getAttribute("action")))
			.catch((error) => alert(error));
	};

	return (
		<>
			<form
				name="Kontakt Formular"
				method="post"
				action="/danke"
				data-netlify="true"
				/* data-netlify-honeypot="bot-field" */
				onSubmit={handleSubmit}
				sx={{
					px: [3, 2, 2],
					div: {
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						width: "100%",
						label: {
							width: "100%",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							input: {
								width: "100%",
								mb: 3,
								p: 1,
								fontSize: [2, 3, 3],
							},
						},
						button: {
							cursor: "pointer",
							appearance: "none",
							alignSelf: "flex-end",
							textAlign: "center",
							mt: 1,
							mx: "0px",
							color: "background",
							fontSize: [2, 3, 3],
							bg: "primary",
							borderRadius: "100px",
							borderStyle: "none",
							height: [1, 2, 2],
							width: [4, 6, 6],
						},
					},
				}}
			>
				{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
				<input
					type="hidden"
					name="form-name"
					value="Kontakt Formular"
				/>
				{/* Required for Netlify forms spam filter
				<span hidden>
					<label>
						<span hidden>Bitte nicht ausfüllen (Botfeld): </span>
						<input name="bot-field" onChange={handleChange} />
					</label>
				</span> */}
				<div>
					<label>
						<span hidden>
							Ihr Name:
							<br />
						</span>
						<input
							type="text"
							name="Name"
							placeholder="Ihr Name"
							onChange={handleChange}
						/>
					</label>
				</div>
				<div>
					<label>
						<span hidden>
							Ihre E-Mail Adresse:
							<br />
						</span>
						<input
							type="email"
							name="E-Mail"
							placeholder="Ihre E-Mail Adresse"
							onChange={handleChange}
						/>
					</label>
				</div>
				<div>
					<label>
						<span hidden>
							Ihre Telefonnummer:
							<br />
						</span>
						<input
							type="tel"
							name="Telefon"
							placeholder="Ihre Telefonnummer"
							onChange={handleChange}
						/>
					</label>
				</div>
				<div>
					<button type="submit">Weiter</button>
				</div>
			</form>
		</>
	);
};

export default Form;

/** @jsx jsx */
import { jsx } from "theme-ui";
import Layout from "../components/Layout";
import Form from "../components/Form";

export default () => {
	return (
		<Layout>
			<section
				sx={{
					maxWidth: "40rem",
					margin: [null, "0 auto", "0 auto"],
					pb: 5,
				}}
			>
				<h1
					sx={{
						my: 3,
						fontSize: [4, 5, 5],
						textAlign: "center",
						fontWeight: "lightbold",
					}}
				>
					Jetzt Termin vereinbaren
				</h1>
				<h2
					sx={{
						px: [2, "0px", "0px"],
						fontSize: [2, 3, 3],
						fontWeight: "semibold",
						textAlign: "center",
						mt: "0px",
						mb: 2,
					}}
				>
					In unserem kostenfreien Erstgespräch:
				</h2>
				<ol
					sx={{
						pl: 4,
						pr: 2,
						mt: "0px",
						mb: 3,
						li: {
							fontSize: [2, 3, 3],
						},
					}}
				>
					<li>
						Wieso Ihr Onlineauftritt wichtiger als je zuvor ist
						und was das für Ihr Planungsbüro konkret bedeutet.
					</li>
					<li>
						Welche Webstrategie zu Ihren Unternehmenszielen passt.
					</li>
					<li>
						Das verborgene Potential Ihrer Webseite und wie sie es
						zukünftig nutzen.
					</li>
					<li>
						Der Weg zum wirksamen Internetauftritt, Schritt für
						Schritt.
					</li>
				</ol>
				<Form />
			</section>
		</Layout>
	);
};
